.App {
  flex: 1;
  display: flex;
  background-color: #f5f5f5;
}

.centered-content-root {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SnackbarItem-action .MuiIconButton-root {
  color: inherit;
}

.row {
  display: flex;
  align-items: center;
}

.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-top {
  display: flex;
  align-items: flex-start;
}

.column-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-between {
  justify-content: space-between;
}

.self-stretch {
  align-self: stretch;
}

.margin-bottom {
  margin-bottom: 16px !important;
}

.big-margin-bottom {
  margin-bottom: 32px !important;
}

.small-margin-bottom {
  margin-bottom: 8px !important;
}

.margin-top {
  margin-top: 16px !important;
}

.big-margin-top {
  margin-top: 32px !important;
}

.small-margin-right {
  margin-right: 8px !important;
}

.small-margin-left {
  margin-left: 8px !important;
}

.margin-left {
  margin-left: 16px !important;
}

.big-margin-left {
  margin-left: 32px !important;
}

.text-with-returns {
  white-space: pre-line;
}

.text-center {
  text-align: center;
}

.vcenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bold {
  font-weight: bold !important;
}

.padding {
  padding: 16px;
}

.project-modal-root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-modal-paper {
  padding: 32px;
  min-height: 450px;
  max-height: 850px;
  min-width: 450px;
  max-width: 850px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.charter-modal {
  max-width: inherit;
}

.small-modal {
  max-width: 450px;
}

.empty {
  width: 450px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.empty .MuiTypography-root {
  white-space: pre-line;
}

.clickable:hover {
  cursor: pointer;
}

.clickable-opacity:hover {
  cursor: pointer;
  opacity: 0.7;
}

.deactivated td {
  color: #aaa;
}

a {
  color: blue;
  text-decoration: none;
}

a.attachment {
  color: #333;
  font-weight: bold;
  text-decoration: none;
}

.comment-tooltip a {
  color: white;
  text-decoration: underline;
  font-style: italic;
}

#quill-editor {
  display: flex;
  flex-direction: column;
  max-height: 600px;
}

#quill-editor .ql-container {
  flex: 1;
  overflow-y: auto;
}
