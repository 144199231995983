.dashboard-no-project {
  width: 450px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dashboard-no-project .MuiTypography-root {
  white-space: pre-line;
}

.dashboard-figures {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 32px 0 48px 0;
}
