.rate-card,
.rate-card-current {
  padding: 16px;
  width: 35em;
}

.rate-card-current {
  font-weight: bold;
}

.edit-rate-button {
  margin-top: 16px;
}

.rates-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}
