.signer-form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.signer-form-row > .not-only-textfield {
  margin-left: 16px;
  flex: 1;
}

.signer-details {
  width: 700px;
}

.signer-form-info {
  margin-bottom: 8px;
}

.signer-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.signer-details-root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.signer-details-readonly {
  flex: 1;
}
