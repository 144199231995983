.obliged-list-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.obliged-list-item {
  padding: 32px;
  width: 850px;
}
